@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;700&family=Montserrat&family=Nunito+Sans:wght@300&family=Nunito:wght@200;300&family=Oswald:wght@300;600&display=swap');
.custome_nav{
  padding: 2rem;
  background-color: white;
  position: sticky;
  top: 1px;
}

.topnav{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color:#fdda30;
  position: relative;
  border-top-right-radius: 0.5rem;
  border-top-left-radius: 0.5rem;
}

.app{
  background-color: rgb(230, 230, 229);
  width: 100%;
}

.nav-link{
  font-weight: bold;
  color: rgb(247, 85, 85);
  font-size: 1.2rem;
  margin: 1rem;
}

.nav-link:hover{
  background-color: rgb(218, 229, 250);
  color: rgb(44, 114, 243);
  border-radius: 0.5rem;
  transition: 0.5s;
}

.dis-menu{
  margin-top: 0.3rem;
}

.login-container {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  padding: 1rem;
  margin-top: 6rem;
  border-radius: 0.5rem;
}

.contact-container{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  padding: 0.3rem;
}

.signup-container{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  padding: 1rem;
  margin-top: 4rem;
  margin-bottom: 3rem;
  border-radius: 0.5rem;
}

.password-container{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  padding: 1rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
}

body{
  background-color: rgb(230, 230, 229);
  height: 100vh;
}

.home-page{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
  margin-top: 0.6rem;
}

h1,h2,h3,h4,h5,span,p,label{
   
font-family: 'Montserrat', sans-serif;
font-family: 'Nunito', sans-serif;
font-family: 'Nunito Sans', sans-serif;
 
}


.event-front-page{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  background-color: #fff;
}

.event-front-page:hover{
  box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  transition: 0.2s;
}

.footer{
  margin-bottom: 0rem;
  background-color: #fdda30;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  position: relative;
}

.acoordian{
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  margin-top: 7rem;
}

::-webkit-scrollbar {
  width: 0px;
  overflow-y: none;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #888; 
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

.home-p-box{
  height: 36rem;
  padding: 0.3rem;
}

.icons-container:hover > .icons{
  -webkit-animation-name: icons;
  animation-name: icons;
  -webkit-animation-duration: .75s;
  animation-duration: .75s;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}


@-webkit-keyframes icons {
  0%, 20%, 40%, 60%, 80%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
  }
  20% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
  -webkit-transform: scale3d(.9, .9, .9);
  transform: scale3d(.9, .9, .9);
  }
  60% {
  opacity: 1;
  -webkit-transform: scale3d(1.03, 1.03, 1.03);
  transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
  -webkit-transform: scale3d(.97, .97, .97);
  transform: scale3d(.97, .97, .97);
  }
  100% {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  }
  @keyframes icons {
  0%, 20%, 40%, 60%, 80%, 100% {
  -webkit-transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  transition-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
  }
  0% {
  opacity: 0;
  -webkit-transform: scale3d(.3, .3, .3);
  transform: scale3d(.3, .3, .3);
  }
  20% {
  -webkit-transform: scale3d(1.1, 1.1, 1.1);
  transform: scale3d(1.1, 1.1, 1.1);
  }
  40% {
  -webkit-transform: scale3d(.9, .9, .9);
  transform: scale3d(.9, .9, .9);
  }
  60% {
  opacity: 1;
  -webkit-transform: scale3d(1.03, 1.03, 1.03);
  transform: scale3d(1.03, 1.03, 1.03);
  }
  80% {
  -webkit-transform: scale3d(.97, .97, .97);
  transform: scale3d(.97, .97, .97);
  }
  100% {
  opacity: 1;
  -webkit-transform: scale3d(1, 1, 1);
  transform: scale3d(1, 1, 1);
  }
  } 

  .district_card_item{
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  transition: 0.2s;
  background-color: #fff;
  border-radius: 0.5rem;
  }

  .district_card_item:hover{
    box-shadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
    transition: 0.2s;
  }

  .home-p-box_dg{
    height: 26rem;
  }

  .home-p-box_district{
    height: 24rem;
  }

  .home-p-box_international{
    height: 25rem;
  }

  .member-search{
    height: 40rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    background-color: #fff;
    margin-bottom: 1rem;
    border-radius: 0.4rem;
    margin-top: 0.43rem;
  }

  .home-p-box_member,.club-data-container{
    padding: 5rem;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    border-radius: 0.5rem;
    margin-bottom: 1rem;
  }

  .member-heading{
    background-color: #fff;
    border-radius: 0.5rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
    position: relative;
  }

 .carausel{
   border-radius: 30% 70% 70% 30% / 30% 30% 70% 70% ;
 }

 .regBtn{
  background-color: white;
  color: red;
  padding: 0.8rem; 
  width: 14rem;
  border: 2px solid red;
 }

 .regBtn:hover {
  background-color: red;
  color: white;
  transition: 0.5s;
 }

 .carousel-caption {
  top: 0;
  bottom: auto;
}